import { faCupTogo } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence, motion } from 'framer-motion'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import { getLocationSlug } from '../api-utils'
import { location as locationRequests } from '../api-utils/service-requests/location'
import { withAppPageProps } from '../api-utils/service-requests/utils'
import { Locale, makeLocale } from '../components/helpers'
import { Hero } from '../components/hero'
import { SessionlessPage } from '../components/page-wrappers'
import { Layout } from '../components/page-wrappers/layout'
import { GetSessionlessPageProps } from '../components/page-wrappers/types'
import { useUserProfile } from '../hooks/useUserProfile'
import { GetLocationInfoResponse } from '../models/types'
import { Heading, Text } from '../ui/Typography'

type Props = {
  information: GetLocationInfoResponse
}

const listItemContainerVariant = {
  show: {
    transition: {
      staggerChildren: 0.07,
    },
  },
}

const QrCodePage = ({ information }: Props) => {
  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale)
  const translation = translations[locale as Locale]
  const { takeawayEnabled } = information
  const userProfile = useUserProfile()

  return (
    <SessionlessPage information={information} userProfile={userProfile}>
      <Layout
        title={translation.scanToOrder}
        information={information}
        navBarOptions={{ leftButtons: [], transparentBackground: true }}
        classNames={'md:mt-6'}>
        <Hero
          locationName={information.name}
          featureImage={information.featureImageUrl || ''}
          logoImage={information.logoImageUrl || ''}
        />
        <div className="container flex flex-col gap-y-4 pt-8 px-8">
          <div className="container flex flex-col items-center gap-y-4 pt-8 px-8">
            <Heading level={3}>{translation.scanToOrder}</Heading>
            <div>{translation.scanToOrderDescription}</div>
            {takeawayEnabled && (
              <motion.div
                className="grid grid-cols-2 gap-2 pb-2 w-full"
                variants={listItemContainerVariant}
                initial="hidden"
                animate="show">
                <AnimatePresence>
                  <motion.div className={'col-span-2'} key={'menu-or'}>
                    <div className="relative flex items-center py-2 w-full max-w-[250px] mx-auto">
                      <div className="flex-grow border-t border-gray-200"></div>
                      <span className="flex-shrink mx-4 text-gray-400 text-sm">
                        {translation.or}
                      </span>
                      <div className="flex-grow border-t border-gray-200"></div>
                    </div>
                  </motion.div>
                  <motion.div className={'col-span-2'} key={'menu-all'}>
                    <Link
                      className="flex flex-row items-center justify-center gap-x-3 flex-grow-0 text-left rounded-lg py-6 px-6 bg-inline active:scale-[103%] transition-all col-span-2 hover:bg-neutral-200 hover:scale-[102%]"
                      href="/takeaway">
                      <FontAwesomeIcon size="lg" icon={faCupTogo} />
                      <Text
                        shouldUseBrandFont
                        level={1}
                        weight="medium"
                        className="text-ellipsis text-center overflow-hidden whitespace-nowrap">
                        {translation.orOrderTakeaway}
                      </Text>
                    </Link>
                  </motion.div>
                </AnimatePresence>
              </motion.div>
            )}
          </div>
        </div>
      </Layout>
    </SessionlessPage>
  )
}

export default QrCodePage

const getProps: GetSessionlessPageProps<Props> = async (context) => {
  const slug = getLocationSlug(context)
  const information = await locationRequests.information(slug, {}, context)
  return {
    props: {
      information,
    },
  }
}
export const getServerSideProps = withAppPageProps(getProps)

const translations: {
  [key in Locale]: {
    scanToOrder: string
    scanToOrderDescription: string
    or: string
    orOrderTakeaway: string
  }
} = {
  sv: {
    scanToOrder: 'Skanna och beställ',
    scanToOrderDescription: 'Skanna QR-koden på ditt bord för att beställa',
    or: 'eller',
    orOrderTakeaway: 'Byt till takeaway',
  },
  en: {
    scanToOrder: 'Scan to order',
    scanToOrderDescription: 'Scan the QR code at your table to order',
    or: 'or',
    orOrderTakeaway: 'Switch to takeaway',
  },
  no: {
    scanToOrder: 'Skann og bestill',
    scanToOrderDescription: 'Skann QR-koden på bordet ditt for å bestille',
    or: 'eller',
    orOrderTakeaway: 'Bytt til takeaway',
  },
}
