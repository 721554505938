import { faCheck, faClose, faGlobe } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouter } from 'next/router'
import nookies from 'nookies'
import React from 'react'
import { useState } from 'react'

import { useLockBodyScroll } from '../../hooks/useLockBodyScroll'
import { Button } from '../../ui/Button/Button'
import { Heading, Text } from '../../ui/Typography'
import analytics from '../../utils/analytics'
import { TRANSLATION_COOKIE } from '../../utils/constants'
import { Backdrop } from '../backdrop'
import BottomDrawer from '../bottom-drawer'
import { Locale, useTranslations } from '../helpers'
import { LanguageCode, LocalesWithRegions } from './types'
import { translationLanguages } from './utils'

interface Props {
  isLanguageSelectorOpen: boolean
  setIsLanguageSelectorOpen: (isOpen: boolean) => void
}

export const LanguageSelector = ({ isLanguageSelectorOpen, setIsLanguageSelectorOpen }: Props) => {
  const tr = useTranslations(translations)
  const router = useRouter()
  useLockBodyScroll(isLanguageSelectorOpen)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const translationCookie = nookies.get(null, { path: '/' })[TRANSLATION_COOKIE] ?? undefined

  const languageChoices: { langCode: LanguageCode | null; name: string }[] = [
    { langCode: null, name: tr.noLanguageOption },
    ...translationLanguages,
  ]

  const handleUpdateLocale = async (lang: LanguageCode | null) => {
    const { pathname, asPath, query, locale } = router
    const matchingLocaleWithRegion = Object.values(LocalesWithRegions).find(
      (locale) => locale.split('-')[0] === lang
    )
    if (matchingLocaleWithRegion) {
      await router.push({ pathname, query }, asPath, { locale: matchingLocaleWithRegion })
    } else if (lang === null) {
      await router.push({ pathname, query }, asPath, { locale })
    } else {
      // Fallback language/loacale of static Karma product is English since most people speak English
      await router.push({ pathname, query }, asPath, { locale: 'en-US' })
    }
  }

  const handleTranslationChange = async (lang: LanguageCode | null) => {
    analytics.events.translation.selectLanguage({ lang })
    setIsLoading(true)
    nookies.destroy(null, TRANSLATION_COOKIE, { path: '/' })
    if (lang !== null) {
      nookies.set(null, TRANSLATION_COOKIE, lang, {
        path: '/',
        maxAge: 365 * 24 * 60 * 60,
      })
    }
    await handleUpdateLocale(lang)
    setIsLoading(false)
    setIsLanguageSelectorOpen(false)
  }

  const handleCloseDrawer = () => {
    analytics.events.translation.closeLanguageSelector()
    setIsLanguageSelectorOpen(false)
  }

  const languageSelectorPortalId = `language-selector-drawer-portal-root-${router.pathname}`

  return (
    <>
      <Backdrop shouldShow={isLanguageSelectorOpen} onClickCallback={handleCloseDrawer} />
      <BottomDrawer
        shouldShowBorder={true}
        isOpen={isLanguageSelectorOpen}
        portalId={languageSelectorPortalId}
        layer={3}>
        <div className="p-4 pt-6 max-h-[70vh] h-full flex flex-col max-w-lg mx-auto">
          <div className="flex-none relative">
            <Heading className="text-center" level={4}>
              {tr.selectLanguageTitle}
            </Heading>
            <div className="w-max absolute right-0 top-[-0.65rem]">
              <Button type="tertiary" onClick={handleCloseDrawer}>
                <FontAwesomeIcon icon={faClose} size="lg" />
              </Button>
            </div>
          </div>

          <div className="rounded p-4 bg-blue-100 mt-6 mb-2 flex items-center gap-4 text-blue-400">
            <FontAwesomeIcon icon={faGlobe} />{' '}
            <Text level={3} color="inherit">
              The menu is lovingly translated by AI, occasional quirks may occur!
            </Text>
          </div>

          <div className="overflow-y-auto flex-automy-2 mt-2 ">
            {languageChoices.map((languageChoice) => {
              const { langCode, name } = languageChoice
              const isSelected =
                langCode === translationCookie ||
                (!translationCookie && languageChoice.langCode === null)
              return (
                <div className="border-solid border-t-2 border-slate-100 px-2" key={`${langCode}`}>
                  <Button
                    block
                    type="tertiary"
                    loading={isSelected && isLoading}
                    onClick={() => {
                      handleTranslationChange(langCode)
                    }}>
                    <div className="flex items-center w-full text-left">
                      <Text
                        level={2}
                        className="text-ellipsis w-full overflow-hidden whitespace-nowrap">
                        {name}
                      </Text>

                      {isSelected && <FontAwesomeIcon icon={faCheck} size="lg" />}
                    </div>
                  </Button>
                </div>
              )
            })}
          </div>
        </div>
      </BottomDrawer>
    </>
  )
}

const translations: {
  [key in Locale]: {
    selectLanguageTitle: string
    noLanguageOption: string
  }
} = {
  sv: {
    selectLanguageTitle: 'Välj menyspråk',
    noLanguageOption: 'Ingen översättning',
  },
  en: {
    selectLanguageTitle: 'Pick menu language',
    noLanguageOption: 'No translation',
  },
  no: {
    selectLanguageTitle: 'Velg menyspråk',
    noLanguageOption: 'Ingen oversettelse',
  },
}
