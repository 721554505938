import { IncomingMessage, ServerResponse } from 'http'
import { NextApiRequest, NextApiResponse } from 'next'
import nookies from 'nookies'

// Context wrapper that allows passing either the getServerSideProps() or the API page context
// to the cookie functions. Don't need to pass anything if we're requesting cookies
// from the frontend.
type CookieRequestContext = { req: IncomingMessage } | { req: NextApiRequest }
type CookieResponseContext<P extends {}> = { res: ServerResponse } | { res: NextApiResponse<P> }

// Export unified context for convenience for functions that that read/write cookies. Using
// the NextPageContext works, but offer a more specific alternative that can be used with
// NextApiRequest/NextApiResponse as well.
export type CookieServerSideReqRes<P extends {}> = CookieRequestContext & CookieResponseContext<P>

const CookieLifeTime = {
  '1h': 60 * 60,
  '1d': 60 * 60 * 24,
  '1m': 60 * 60 * 24 * 30,
  '1y': 60 * 60 * 24 * 365,
}

const resolveDomain = (
  context: CookieRequestContext | undefined,
  scope: DomainScope
): string | undefined => {
  let hostName = undefined
  if (typeof window !== 'undefined') {
    hostName = window.location.hostname // only works in the frontend
  } else if (context?.req) {
    hostName = context.req.headers.host // only works in the backend
  }

  //Remove any port number from the hostname
  hostName = hostName?.split(':')[0]

  if (scope === 'any-store' || scope === 'any-karma-domain') {
    if (hostName === 'localhost') {
      return hostName
    }

    const domainSuffixes = ['.development.karma.life', '.karma.life', '.karma.local']

    for (const suffix of domainSuffixes) {
      if (hostName?.endsWith(suffix)) {
        if (scope === 'any-store') {
          return `.store${suffix}`
        }
        return suffix
      }
    }
  }

  // undefined means "use default", which is to default to whatever the domain of the request or window is.
  // In practice this means the store-specific domain.
  return undefined
}

const readCookie = (path: string, context?: CookieRequestContext): string => {
  const cookies = nookies.get(context, { path: '/' })
  return cookies[path] ?? null
}

const setCookieForStore = <P extends {}>(
  context: CookieResponseContext<P> | null,
  name: string,
  value: string,
  domain: string | undefined,
  lifeTimeInSeconds: number,
  secure: boolean,
  httpOnly: boolean
) => {
  nookies.set(context, name, value, {
    path: '/',
    domain,
    maxAge: lifeTimeInSeconds,
    secure,
    httpOnly,
  })
}

const clearCookie = <P extends {}>(
  context: CookieResponseContext<P> | undefined,
  path: string,
  domain: string | undefined
) => {
  nookies.destroy(context, path, { path: '/', domain })
}

type DomainScope =
  | 'any-karma-domain' // .karma.life
  | 'any-store' // .store.karma.life,
  | 'store' // mm.store.karma.life

type CookieOptions<T> = {
  fromCookie?: (cookieValue: string) => T | null
  toCookie?: (value: T) => string | null
  isValid: (value: T) => boolean
  lifeTime?: keyof typeof CookieLifeTime
  secure?: boolean
  httpOnly?: boolean
  domainScope: DomainScope
}

export class Cookie<T = string> {
  private readonly name: string
  private readonly fromCookie: (cookieValue: string) => T | null
  private readonly toCookie: (value: T) => string | null
  private readonly isValid: (value: T) => boolean
  private readonly lifeTimeInSeconds: number
  private readonly secure: boolean
  private readonly httpOnly: boolean
  private readonly domainScope: DomainScope

  constructor(name: string, options: CookieOptions<T>) {
    this.fromCookie = options.fromCookie ?? ((cookieValue) => cookieValue as unknown as T)
    this.toCookie = options.toCookie ?? ((value) => value as unknown as string)
    this.isValid = options.isValid
    this.name = name
    this.lifeTimeInSeconds = CookieLifeTime[options.lifeTime ?? '1y']
    this.secure = options.secure ?? false
    this.httpOnly = options.httpOnly ?? false
    this.domainScope = options.domainScope ?? 'store'
  }

  get(context?: CookieRequestContext): T | null {
    const rawValue = readCookie(this.name, context)
    const value = this.fromCookie(rawValue)
    if (value && this.isValid(value)) {
      return value
    } else if (rawValue !== null) {
      console.log(`ignoring invalid cookie value: ${rawValue}`, value)
    }
    return null
  }

  set<P extends {}>(value: T, context?: CookieServerSideReqRes<P> | undefined) {
    if (!this.isValid(value)) {
      console.log('ignoring invalid cookie value', value)
      return
    }
    const cookieValue = this.toCookie(value)
    if (cookieValue) {
      const domain = resolveDomain(context, this.domainScope)

      if (this.domainScope !== 'store' && !domain) {
        // Skip storing the cookie if it's meant to be non-store specific but we failed to resolve the
        // domain to put it on. This is known to happen sometimes with next js and hot reloading.
        const msg = `Ignored writing of value to cookie ${this.name} because it's not store-specific and we can't know the domain`
        console.log(msg)
        return
      }

      setCookieForStore(
        context ?? null,
        this.name,
        cookieValue,
        domain,
        this.lifeTimeInSeconds,
        this.secure,
        this.httpOnly
      )
    }
  }

  clear<P extends {}>(context?: CookieServerSideReqRes<P>) {
    const domain = resolveDomain(context, this.domainScope)
    clearCookie(context, this.name, domain)
  }
}
