import * as t from 'io-ts'
import { UUID } from 'io-ts-types'
import { DateTime } from 'luxon'
import { v4 } from 'uuid'

import { CurrencyCode } from '../../models/types'
import { USER_SESSION_PATH } from '../../utils/constants'
import { Cookie, CookieServerSideReqRes } from '../../utils/cookies'
import { CorrelationLogger } from '../logger'
import { validate } from '../validation'
import {
  buildHeaders,
  ErrorResponse,
  getSafeJsonBody,
  storefrontServiceGet,
  storefrontServicePost,
  withUserSessionHeaders,
} from './utils'

type PostUserSignInArgs = { email: string; returnTo: string }
export const postUserSignIn = async (args: PostUserSignInArgs) => {
  const cid = v4()
  const logger = CorrelationLogger({ cid })

  const { email, returnTo } = args
  const response = await storefrontServicePost(
    '/protected/v1/user/sign-in',
    { email, returnTo },
    buildHeaders(cid)
  )
  if (response.status === 201) {
    return { error: null }
  }

  logger.info(`postUserSignIn: Failed to post to storefront service`, {
    responseStatus: response.status,
    responseBody: getSafeJsonBody(response),
  })

  throw new Error(`Failed to send sign in request to backend`)
}

const KlarnaSuccessResponse = t.type({
  type: t.literal('success'),
  userSessionId: UUID,
})
type KlarnaSuccessResponse = t.TypeOf<typeof KlarnaSuccessResponse>

type PostUserSignInThirdPartyArgs = {
  provider: 'klarna'
  payload: unknown
}
export const postUserSignInThirdParty = async (
  args: PostUserSignInThirdPartyArgs
): Promise<KlarnaSuccessResponse | ErrorResponse> => {
  const cid = v4()
  const logger = CorrelationLogger({ cid })

  const { provider, payload } = args
  const response = await storefrontServicePost(
    `/protected/v1/user/sign-in/${provider}`,
    { payload },
    buildHeaders(cid)
  )

  const responseBody = await response.json()
  if (response.status === 200 && KlarnaSuccessResponse.is(responseBody)) {
    const { userSessionId } = responseBody
    return { type: 'success', userSessionId }
  }

  logger.info(`postUserSignInThirdParty: Failed to post to storefront service`, {
    responseStatus: response.status,
    responseBody,
  })

  return {
    code: responseBody?.code || 'UNKNOWN_ERROR',
  }
}

type GetUserProfileResponse = {
  userSessionId: UUID
} & UserProfileData
export const getUserProfile = async <P extends {}>(args: {
  context: CookieServerSideReqRes<P>
  cid: string
  slug?: string
}): Promise<GetUserProfileResponse | null> => {
  const { context, cid, slug } = args
  const userSessionId = userSessionCookie.get(context)
  if (!userSessionId) {
    return null
  }
  const logger = CorrelationLogger({ cid })
  let url = '/protected/v1/user/profile'
  if (slug) {
    url += `?location=${encodeURIComponent(slug)}`
  }
  const response = await storefrontServiceGet(
    url.toString(),
    withUserSessionHeaders(userSessionId, cid)
  )

  if (response.status == 403) {
    logger.info(`UserSession feature not enabled for ${slug}`)
    // Deliberately not clearing the cookie here, keep the cookie for other
    // locations where the feature might be enabled.
    return null
  }

  if (response.status == 404 || response.status === 401) {
    // NOTE(christoffer) Log clearing of the cookie to aid in debugging where a users session is lost.
    // While the session id sensitive information, it should be fine to log since it's server side only
    // and the id is assumed to be invalid/unusable here.
    const msg = `Attempted lookup for user session returned zero or expired results; clearing cookie`
    logger.info(msg, { clearedId: userSessionId })
    userSessionCookie.clear(context)
    return null
  }

  const responseData = await response.json()
  if (response.status !== 200) {
    const error = validate(ErrorResponse, responseData)
    throw new Error(error.code)
  }

  const userProfile = validate(UserProfileData, { ...responseData, userSessionId })
  userSessionCookie.set(userSessionId, context) // Cookie refresh
  return userProfile
}

export const userSessionSignOut = async (userSessionId: UUID): Promise<void> => {
  const cid = v4()
  const logger = CorrelationLogger({ cid })
  const response = await storefrontServicePost(`/protected/v1/user/sign-out`, { userSessionId })
  const responseData = await response.json()
  if (response.status !== 200) {
    const error = validate(ErrorResponse, responseData)
    throw new Error(error.code)
  }
  logger.info(`User session ${userSessionId} ended`)
}

const OrderHistoryOrder = t.type({
  id: UUID,
  dateIso: t.string,
  amountCents: t.number,
  amountCurrency: CurrencyCode,
  locationId: t.number,
  locationName: t.string,
  locationLogoUrl: t.string,
})
type OrderHistoryOrder = t.TypeOf<typeof OrderHistoryOrder>

const OrderHistoryRefund = t.type({
  orderId: UUID,
  dateIso: t.string,
  amountCurrency: t.string,
  amountCents: t.number,
})
export type OrderHistoryRefund = t.TypeOf<typeof OrderHistoryRefund>

const UserOrderHistory = t.type({
  orders: t.array(OrderHistoryOrder),
  refunds: t.array(OrderHistoryRefund),
})
type UserOrderHistory = t.TypeOf<typeof UserOrderHistory>

export const getUserOrderHistory = async (args: {
  userSessionId: UUID
  cid: string
  loyaltySlug?: string
}): Promise<OrderWithRefunds[]> => {
  const { userSessionId, cid, loyaltySlug } = args
  const logger = CorrelationLogger({ cid })

  let url = '/protected/v1/user/order-history'
  if (loyaltySlug) {
    url += `?location=${encodeURIComponent(loyaltySlug)}`
  }
  const response = await storefrontServiceGet(url, withUserSessionHeaders(userSessionId, cid))

  if (response.status !== 200) {
    const msg = `Failed fetching orders from service. Expected status 200, got ${response.status}`
    logger.debug(msg)
    throw new Error(`Failed fetching orders, got non-successful response from service`)
  }

  const responseData = await response.json()
  const orderHistory = validate(UserOrderHistory, responseData)
  const ordersWithRefunds = groupOrderWithRefunds(orderHistory)
  ordersWithRefunds.sort((a, b) => {
    return DateTime.fromISO(b.order.dateIso).valueOf() - DateTime.fromISO(a.order.dateIso).valueOf()
  })
  return ordersWithRefunds
}

export type OrderWithRefunds = {
  order: OrderHistoryOrder
  refunds: OrderHistoryRefund[]
}

const groupOrderWithRefunds = (orderHistory: UserOrderHistory) => {
  const ordersWithRefunds: OrderWithRefunds[] = []
  const refundsByOrderId = orderHistory.refunds.reduce((acc, refund) => {
    const key = refund.orderId as keyof typeof acc
    const refundsForOrder = acc[key] || []
    refundsForOrder.push(refund)
    acc[key] = refundsForOrder
    return acc
  }, {} as Record<string, OrderHistoryRefund[]>)

  orderHistory.orders.forEach((order) => {
    const refundsForOrder = refundsByOrderId[order.id] || []
    ordersWithRefunds.push({ order, refunds: refundsForOrder })
  })

  return ordersWithRefunds
}

const UserLocationSettings = t.type({
  explicitMarketingConsent: t.boolean,
  enrolledInLoyaltyProgram: t.boolean,
})

export const UserProfileData = t.intersection([
  t.type({
    userSessionId: UUID,
    displayName: t.string,
    phoneNumber: t.union([t.string, t.null]),
    email: t.string,
  }),
  t.partial({
    locationSettings: UserLocationSettings,
  }),
])

export type UserProfileData = t.TypeOf<typeof UserProfileData>

export const userSessionCookie = new Cookie<UUID>(USER_SESSION_PATH, {
  isValid: (val: string) => {
    return UUID.is(val)
  },
  // NOTE(christoffer) We have to store this on .karma.life because it's used on pay.karma.life
  domainScope: 'any-karma-domain',
  httpOnly: true,
  lifeTime: '1m',
})

type MarketingUnsubscribeArgs = {
  user_id: string
  program_id: string
  token: string
  campaign_id?: string
}
export const postMarketingUnsubscribe = async (args: MarketingUnsubscribeArgs) => {
  const cid = v4()
  const logger = CorrelationLogger({ cid })

  const { user_id, program_id, token, campaign_id } = args
  const response = await storefrontServicePost(
    '/protected/v1/user/unsubscribe',
    { user_id, program_id, token, campaign_id },
    buildHeaders(cid)
  )
  if (response.status === 201) {
    return { error: null }
  }

  logger.info(`postMarketingUnsubscribe: Failed to post to storefront service`, {
    responseStatus: response.status,
    responseBody: getSafeJsonBody(response),
  })

  throw new Error(`Failed to send unsubscribe request to backend`)
}
