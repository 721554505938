import React, { FC, useRef } from 'react'
import { createPortal } from 'react-dom'

import { useMountAndUnmountPortal, usePortalRoot } from '../../hooks'
import { useElementChildrensHeight } from './hooks'

type Props = {
  isOpen: boolean
  shouldShowBorder?: boolean
  shouldShowBoxShadow?: boolean
  shouldShowRoundedCorners?: boolean
  shouldUseDynamicHeight?: boolean
  shouldUseWindowHeight?: boolean
  transitionSpeedIn?: TransionSpeeds
  transitionSpeedOut?: TransionSpeeds
  layer: 1 | 2 | 3 | 4 | 5
  portalId: string
  className?: string
}

type TransionSpeeds = 'fast' | 'medium' | 'slow'

const transitionSpeedClasses: Record<TransionSpeeds, string> = {
  fast: 'duration-200',
  medium: 'duration-300',
  slow: 'duration-500',
}

const BottomDrawer: FC<Props> = ({
  children,
  isOpen = false,
  shouldShowBorder = false,
  shouldShowBoxShadow = false,
  shouldShowRoundedCorners = true,
  shouldUseDynamicHeight = false,
  transitionSpeedIn = 'medium',
  transitionSpeedOut = 'medium',
  layer,
  portalId,
  className = '',
}) => {
  const contentRef = useRef<HTMLDivElement>(null)

  const portalRoot = usePortalRoot(portalId)
  useMountAndUnmountPortal(portalRoot)
  const wrapperHeight = useElementChildrensHeight({
    contentRef,
    children,
    shouldUseDynamicHeight,
  })

  const transitionClassesIn =
    transitionSpeedClasses[transitionSpeedIn] ?? transitionSpeedClasses['medium']
  const transitionClassesOut =
    transitionSpeedClasses[transitionSpeedOut] ?? transitionSpeedClasses['medium']

  return createPortal(
    <div
      // this is the element that animates translate (slide up/down)
      style={{
        transform: isOpen ? 'translate(0, 0)' : 'translate(0, 100%)',
      }}
      className={`fixed inset-x-0 bottom-0 flex flex-col justify-end bg-opacity-0 ease-out-cubic ${
        isOpen ? transitionClassesIn : transitionClassesOut
      } z-drawer_${layer} ${className}`}>
      <div className="h-full flex flex-col justify-end pointer-events-auto">
        <div
          className={`${
            children
              ? `bg-white
                ${shouldShowBorder ? 'border-t' : 'border-none'}
                ${shouldShowBoxShadow ? 'shadow-t-2xl' : 'shadow-none'}
                ${shouldShowRoundedCorners ? 'rounded-t-2xl' : ''}`
              : ''
          }`}>
          <div
            // this is the element that will animate in height when content changes
            ref={contentRef}
            className={`${isOpen ? transitionClassesIn : transitionClassesOut} relative`}
            style={{
              height: wrapperHeight,
            }}>
            {children}
          </div>
        </div>
      </div>
    </div>,
    portalRoot
  )
}

export default BottomDrawer
