import { createContext, PropsWithChildren, useState } from 'react'

import { SignInConfigs } from '../../utils/page-types'

export type SignInContext = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const SignInContext = createContext<SignInContext>({
  isOpen: false,
  setIsOpen: () => ({}),
})

type Props = PropsWithChildren<{
  defaultEmail: string | null
  signInConfigs: SignInConfigs
}>

export const SignInProvider = (props: Props) => {
  const { children, defaultEmail, signInConfigs } = props
  const [isOpen, _setIsOpen] = useState(false)

  const setIsOpen = (isOpen: boolean) => {
    _setIsOpen(isOpen)
  }

  /*The sign in drawer lived here but had to be removed for now due to not supporting multiple slugs for Klarna sign in, we'll rebuild it when we have a slugless version of the storefront*/

  return <SignInContext.Provider value={{ isOpen, setIsOpen }}>{children}</SignInContext.Provider>
}
