export const PHONE_NUMBER_COOKIE_PATH = 'phone-number'
export const EMAIL_COOKIE_PATH = 'email'
export const COUNTRY_CODE_COOKIE_PATH = 'country-code'
export const DISCOUNT_CODE_COOKIE_PATH = 'discount-code'
export const PAYMENT_METHOD_COOKIE_PATH = 'payment-method'
export const USER_SESSION_PATH = 'user-session-id'
export const STOREFRONT_SESSION_ID_PATH = 'sessionId'
export const STOREFRONT_CLIENT_ID_PATH = 'clientId'

export const PAGE_TRANSITION_DURATION_MS = 200
export const PAGE_TRANSITION_DURATION_S = PAGE_TRANSITION_DURATION_MS / 1000

export const REALTIME_TAB_ITEM_SELECTED = 'ITEM_SELECTED'
export const REALTIME_TAB_MEMBERS_CHANGED = 'UPDATE_TAB_MEMBERS'

export const REALTIME_TAB_ORDER_PLACED = 'REALTIME_TAB_ORDER_PLACED'
export const getRealtimeTabChannelName = (tabId: string) => `TAB_ITEM_SELECTION:${tabId}`
export const TRANSLATION_COOKIE = 'translatedLanguage'
export const RETURN_URL_COOKIE_PATH = 'returnUrl'
