import { RETURN_URL_COOKIE } from '../hooks/useSignIn/cookie'

export const getUserSignInURL = () => {
  const returnTo = encodeURIComponent(window.location.href)
  RETURN_URL_COOKIE.set(returnTo)

  const hostname = window.location.hostname

  if (hostname.endsWith('.store.development.karma.life')) {
    return `https://my.store.development.karma.life/sign-in`
  }

  if (hostname.endsWith('.store.karma.life')) {
    return `https://my.store.karma.life/sign-in`
  }

  // Default for localhost or other unknown hosts
  return '/sign-in'
}
