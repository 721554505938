import { useRouter } from 'next/router'
import React from 'react'
import { createPortal } from 'react-dom'

import { useMountAndUnmountPortal, usePortalRoot } from '../../hooks'

type Props = {
  shouldShow: boolean
  onClickCallback: () => void
  layer?: 'default' | 'drawer_5' | 'modal'
  id?: string
}

const zLayerClasses = {
  default: 'z-backdrop',
  drawer_5: 'z-drawer_5_backdrop',
  modal: 'z-modal_backdrop',
}

export function Backdrop({ shouldShow, onClickCallback, layer = 'default', id = '' }: Props) {
  const router = useRouter()

  const portalId = `backdrop-portal-root-${router.pathname}-${id}`
  const portalRoot = usePortalRoot(portalId)
  useMountAndUnmountPortal(portalRoot)

  const zLayerClass = zLayerClasses[layer]

  /* 
  Using 'pointer-events-none' to make the element ignore pointer events. 
  The pointer events will still trigger on child elements and pass-through to elements that are “beneath” the target.

  This will allow us to use both in and out transition animation of the opacity because the element can still be "displayed"
  without blocking the pointer events.
  */

  return createPortal(
    <div
      onClick={onClickCallback}
      className={`fixed inset-x-0 bottom-0 flex flex-col justify-end ${zLayerClass} transition-opacity duration-300 ease-out-cubic bg-black h-full ${
        shouldShow ? 'opacity-50' : 'opacity-0 pointer-events-none'
      }`}
    />,
    portalRoot
  )
}
