import { RETURN_URL_COOKIE_PATH } from '../../utils/constants'
import { Cookie } from '../../utils/cookies'

export const RETURN_URL_COOKIE = new Cookie(RETURN_URL_COOKIE_PATH, {
  isValid: (val) => val?.length > 0,
  lifeTime: '1h',
  secure: true,
  domainScope: 'any-karma-domain',
  httpOnly: false,
})

export const getReturnUrlFromCookie = () => {
  const returnToCookie = RETURN_URL_COOKIE.get()

  if (returnToCookie) {
    return decodeURIComponent(returnToCookie)
  }
  return null
}
